.about-page {
    padding: 2rem;

}

/* Section 1 */
.about-intro {

    text-align: center;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column; /* Ensure vertical alignment */
    align-items: center; /* Center align the elements */
    margin-top: 80px; /* Add margin-top */
    margin-bottom: 80px; /* Add margin-bottom */
}

.about-intro h1 {
    font-size: 3rem;
    margin-bottom: 1rem; /* Add some space between h1 and p */
    color: var(--color-secondary);
}

.about-intro p {
    max-width: 600px;
    margin: 1.5rem auto;
    font-size: 1.1rem;
    color: #6b6b6b;
    line-height: 1.8;
    color: var(--color-white);
}

/* Section 2 & 3 */
.about-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
}

.about-text {
    flex: 1;
    padding: 1.5rem;

}

.about-text h2 {
    font-size: 2rem;
    color: var(--color-secondary);
    margin-bottom: 3rem;
}

.about-text p {
    font-size: 1.1rem;
    color: var(--color-white);
    line-height: 1.8;
}

.about-image {
    flex: 1;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-section {
        flex-direction: column;
    }

    .about-text,
    .about-image {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-text h2 {
        text-align: center;
        order: 1;
    }

    .about-text p {
        order: 2;
    }

    .about-image img {
        width: 90%;
        margin-top: 1rem;
        order: 3;
    }
}

