/* Language switcher container, positioned in the bottom left corner */
.language-switcher-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}

/* Language switcher itself */
.language-switcher {
  position: relative;
  display: inline-block;
  width: calc(48px * 2);
  height: 48px;
  transition: transform 0.17s cubic-bezier(0.26, 0.48, 0.08, 0.9);
}

/* Hide the checkbox input */
.language-switcher input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Language labels (EN and AL) */
.language-switcher .select-en,
.language-switcher .select-al {
  position: absolute;
  font-size: 15px;
  top: 12px;
  transition: color 0.4s;
}

.language-switcher .select-en {
  left: 18px;
}

.language-switcher .select-al {
  right: 16px;
}

/* Default text color for inactive language */
.language-switcher .select-en {
  color: var(--color-inactive); /* Color for EN text when AL is active */
}

.language-switcher .select-al {
  color: var(--color-inactive); /* Color for AL text when EN is active */
}

/* The slider (toggle) */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-background);
  box-shadow: 0 3px 64px rgba(30, 52, 112, 0.1);
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 48px;
  width: 48px;
  left: 0;
  bottom: 0;
  background-color: var(--color-secondary-transparent);
  box-shadow: 0 3px 64px rgba(30, 52, 112, 0.16);
  transition: 0.4s;
}

/* When the input is checked, move the slider */
input:checked + .slider:before {
  transform: translateX(48px);
}

/* Remove background color from slider when checked */
input:checked + .slider {
  background-color: var(--color-background);
}

/* Change text color when toggle is checked */
input:checked ~ .language-switcher .select-en {
  color: var(--color-background); /* Color for EN text when EN is active */
}

input:checked ~ .language-switcher .select-al {
  color: var(--color-background); /* Color for AL text when EN is active */
}

/* Rounded sliders */
.slider.round {
  border-radius: 64px;
}

.slider.round:before {
  border-radius: 50%;
}
