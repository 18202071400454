@font-face {
  font-family: 'Outfit-Black';
  src: url('../public/assets/fonts/Outfit-Black.ttf') format('woff2'),
       url('../public/assets/fonts/Outfit-Black.ttf') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Bold';
  src: url('../public/assets/fonts/Outfit-Bold.ttf') format('woff2'),
       url('../public/assets/fonts/Outfit-Bold.ttf') format('woff');
  font-weight: 700;
  font-style: normal; 
}

@font-face {
  font-family: 'Outfit-ExtraBold';
  src: url('../public/assets/fonts/Outfit-ExtraBold.ttf') format('woff2'),
       url('../public/assets/fonts/Outfit-ExtraBold.ttf') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-ExtraLight';
  src: url('../public/assets/fonts/Outfit-ExtraLight.ttf') format('woff2'),
       url('../public/assets/fonts/Outfit-ExtraLight.ttf') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Light';
  src: url('../public/assets/fonts/Outfit-Light.ttf') format('woff2'),
       url('../public/assets/fonts/Outfit-Light.ttf') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Medium';
  src: url('../public/assets/fonts/Outfit-Medium.ttf') format('woff2'),
       url('../public/assets/fonts/Outfit-Medium.ttf') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Regular';
  src: url('../public/assets/fonts/Outfit-Regular.ttf') format('woff2'),
       url('../public/assets/fonts/Outfit-Regular.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-SemiBold';
  src: url('../public/assets/fonts/Outfit-SemiBold.ttf') format('woff2'),
       url('../public/assets/fonts/Outfit-SemiBold.ttf') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Thin';
  src: url('../public/assets/fonts/Outfit-Thin.ttf') format('woff2'),
       url('../public/assets/fonts/Outfit-Thin.ttf') format('woff');
  font-weight: 100;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

:root {
  --color-primary: #A88C6C;
  --color-secondary: #FBF3D5;
  --color-background: #8B5E3C;
  --color-white: #fff;
  --color-secondary-transparent:rgba(251, 243, 213, 0.35);
  --color-secondary-transparent-offer:rgba(251, 243, 213, 0.7);
  --color-background-transparent-offer:rgba(139, 94, 60, 0.7);
  --color-background-transparent:rgba(139, 94, 60, 0.8);


  --container-width-lg: 86%;
  --container-width-md: 90%;
  --container-width-sm: 94%;
  --form-width: 50%;

  --transition: all 400ms ease;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: "Outfit-Normal", sans-serif;
  line-height: 1.7;
  color: var(--color-white);
}


.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

section h2 {
  text-align: center;
  margin-bottom: 4rem;
}

h1, h2, h3, h4, h5 {
  line-height: 1.2;
}

h1 {
  font-size: 2.4rem;
  font-family: "Outfit-Bold", sans-serif;
}

h2 {
  font-size: 2rem;
  font-family: "Outfit-Bold", sans-serif;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.3rem;
}

h5 {
  font-family: "Outfit-Bold", sans-serif;
  font-size: 0.9rem;
  word-spacing: 0.2rem;
  line-height: 1.5;
}

a {
  color: var(--color-white);
}

p {
  font-family: "Outfit-Regular", sans-serif;
}

img {
  width: 100%;
  display: block;
  object-fit: cover;
  transition: var(--transition);
}

img:hover {
  transform: scale(1.02);
}


.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Other container styles */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

/* Other CSS remains the same..................... */



.btn {
  font-family: 'Outfit-Bold', 'sans-serif';
  display: inline-block;
  background: var(--color-background);
  color: var(--color-white);
  padding: 0.5rem 4rem;
  border: 3px solid transparent;
  border-radius: 112px;
  font-weight: 400;
  transition: var(--transition);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adjust shadow to match the design */
}

.btn:hover {
  background: var(--color-primary);
  color: var(--color-secondary);
  border-color: var(--color-primary);
}

.btn-primary {
  background: var(--color-background);
  color: var(--color-white);
}

.btn-secondary {
  background: var(--color-primary);
  color: var(--color-background);
}

.btn-background {
  background: var(--color-background);
  color: var(--color-white);
}
/*====================  Hero Template =============================*/

/* Hero section container */
.header-home {
  background-image: url('../public/assets/Home Page Hero.png'); /* Replace with your background image path */
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 120vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid var(--color-background);
}

.header-about {
  background-image: 
  url('../public/assets/About Us Hero.png');
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid var(--color-background);

}

.header-services {
  background-image: 
  url('../public/assets/Services Hero.png');
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid var(--color-background);
}

.header-offers {
  background-image: 
  url('../public/assets/Offers Hero.png');
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid var(--color-background);
}

.header-contact {
  background-image: 
  url('../public/assets/contact hero.png');
  background-size: cover;
  background-attachment: fixed; /* Fix the background to create parallax effect */
  background-position: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid var(--color-background);
}



header {
  display: flex; /* This makes header a flex container */
  justify-content: center; /* This centers its children horizontally */
  align-items: center; /* This centers its children vertically */
  flex-direction: column; /* This stacks children vertically */
  padding-top: 12rem;

}


.hero-title {
  margin-bottom: 60px;
  letter-spacing: 10px;
  font-weight: 100;
  font-size: 60px;
  color: var(--color-secondary);
  text-align: center; /* Center text */
  filter: drop-shadow(4px 5px 2px rgba(0, 0, 0, 0.25)); /* Initial drop shadow */
  

}

.hero-home {
  margin-bottom: 50px;
  margin-top: -40px;

}

.header-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  z-index: -1;       /* Places the image behind the content */
}

/* Partners Section */
.partners-title {
  font-size: 1.6rem;
  font-family: "Outfit-ExtraLight", sans-serif;
  color: var(--color-secondary);
  margin-right: 1rem;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.partner-section{
  margin-top: 3rem;
  margin-bottom: -13.5rem;
}
.trusted-partners {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--color-background-transparent);
  backdrop-filter: blur(6px);

padding: 50px;
border-radius: 12px;
}

.trusted-partners img {
  height: auto;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
}


@media only screen and (max-width: 991px) {
  .trusted-partners img {
  height: 10vw;
    margin-top: 10px;
}
}

@media only screen and (max-width: 625px) {
  .trusted-partners {
    flex-direction: column;
    justify-content: center;
  }
  .trusted-partners img {
  max-height: 150px;
  height: auto;
  width: 150px;
  padding: 1px;
}

}
/*clinic section*/

.clinic-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 10rem;
}

.image-container {
  flex: 1 1 40%;
  max-width: 45%;
  padding: 10px;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 15px; /* Optional, for rounded corners */
}

.text-container {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 10px;
  color: var(--color-secondary);
}

.text-container h1 {
  font-size: 3em;
  margin-bottom: 40px;
  line-height: 5rem;
  color: var(--color-secondary);
}

.text-container p {
  font-size: 1.2em;
  margin-bottom: 60px;
}

/* Responsive adjustments for tablet and phone views */
@media screen and (max-width: 1024px) {
  .clinic-section {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center align items */
  }

  .image-container, .text-container {
    max-width: 100%; /* Make sure containers take full width */
    flex: 1 1 100%; /* Make containers grow to full width */
  }

  .text-container h1 {
    text-align: center; /* Center text */
  }

  .text-container p {
    text-align: center; /* Center text */
  }
}

@media screen and (max-width: 768px) {
  .clinic-section {
    flex-direction: column; /* Ensure column layout on smaller screens */
    align-items: center; /* Center align items */
  }

  .image-container, .text-container {
    max-width: 100%; /* Make sure containers take full width */
    flex: 1 1 100%; /* Make containers grow to full width */
  }

  .text-container h1 {
    text-align: center; /* Center text */
    font-size: 2em; /* Adjust font size for smaller screens */
    line-height: 3rem; /* Adjust line height */
  }

  .text-container p {
    text-align: center; /* Center text */
    font-size: 1em; /* Adjust font size for smaller screens */
    margin-bottom: 40px; /* Adjust margin bottom */
  }

  .trusted-partners {
    border-radius: 12px;
  }


}

.hero-title {
  font-size: 45px;
}
/*types section*/

.center-title {
  margin-top: 8rem;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  line-height: 5rem;
  color: var(--color-secondary);
}

.center-p {
  color: var(--color-secondary);
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 60px;
}

.center-p-normal {
  text-align: center;
}

.types-section {
  padding: 50px 0;
  
}

.types-title {
  text-align: left;
  font-size: 1.5rem;
  color: var(--color-secondary);
  margin-top: 50px;
}

.types-slide {
  display: flex;
  align-items: center;
  padding: 30px;
  background: var(--color-secondary-transparent);
  backdrop-filter: blur(2px);
  border-radius: 22px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  transition: var(--transition);
}

.types-slide:hover {
  transform: scale(1.02);
}

.types-slide img {
  border-radius: 50px;
  box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.3);
  max-width: 30%;
  margin-right: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 30px;
}

.types-slide h4 {
  margin-bottom: 100px;
  margin-left: 100px;
}

.types-slide p {
  margin-bottom: 50px;
  margin-left: 100px;
}

.btn-types {
  margin-left: 100px;
}

.margin-bottom{
  margin-bottom: 40px;
}



/* Responsive adjustments for tablet and phone views */
@media screen and (max-width: 1024px) {
  .types-slide {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center align items */
    text-align: center; /* Center text */
  }

  .types-slide img {
    max-width: 100%; /* Ensure the image takes full width */
    margin: 0 0 20px 0; /* Add margin below the image */
  }

  .types-slide h4, .types-slide p, .btn-types {
    margin: 0; /* Reset margins for centered alignment */
  }

  .btn-types {
    margin-top: 20px; /* Add space above the button */
  }
}

@media screen and (max-width: 768px) {
  .types-slide {
    flex-direction: column; /* Ensure column layout on smaller screens */
    align-items: center; /* Center align items */
    text-align: center; /* Center text */
  }

  .types-slide img {
    max-width: 100%; /* Ensure the image takes full width */
    margin: 0 0 20px 0; /* Add margin below the image */
  }

  .types-slide h4, .types-slide p, .btn-types {
    margin: 0; /* Reset margins for centered alignment */
  }

  .types-slide h4 {
    font-size: 1.5em; /* Adjust font size for smaller screens */
    margin-bottom: 20px; /* Adjust margin bottom */
  }

  .types-slide p {
    font-size: 1em; /* Adjust font size for smaller screens */
    margin-bottom: 20px; /* Adjust margin bottom */
  }

  .btn-types {
    margin-top: 20px; /* Add space above the button */
  
  }

  .header-contact {
    background-position: -600px center; /* Shift image 100px to the left */

  }

  .header-about {
    background-position: -700px center; /* Shift image 100px to the left */

  }
  
}



/*====================  Services Overview Section  =============================*/



.services-overview-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center align both content and image */
  padding: 30px 30px; /* Add padding top and bottom */

}

.services-overview-title {
  align-self: center; /* Center the title */
  padding-top: 20px; /* Space from the top */
  text-align: center; /* Center align text */
  margin-bottom: 4rem; /* Add margin bottom to separate from blobs */
}

.services-overview-title h1 {
  font-size: 2.4rem; /* Increase font size */
  margin:30px;
}


.services-overview-blobs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; /* Increase gap between blobs */
  margin: 0; /* Remove margin-left */
  margin-bottom: 60px;
}

.service-overview-blob-art {
  background: var(--color-secondary-transparent);
  backdrop-filter: blur(2px);
  border-radius: 20px; /* Rounded corners for the blob */
  padding: 20px; /* Padding inside the blob */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none; /* Remove underline from the link */
  color: var(--color-background); /* Text color */
  font-weight: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); /* Shadow for the blob */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.service-overview-blob-art:hover {
  transform: translateY(-5px); /* Slightly raise the blob on hover */
  box-shadow: 0px 8px 3px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.service-overview-blob-art img {
  max-width: 70px; /* Increase based on your icon size */
  margin-bottom: 3px; /* Space between icon and text */
  padding: 10px;
}

.service-overview-blob-art span {
  margin-bottom: -20px;
  padding-top: -50px;
}
/* Backgrounds */
.homepage-background {
width: 100%;
background: url('./../public/assets/Home Page BG.jpg') no-repeat center center;
background-size: cover;
background-attachment: scroll;
}

.about-background {
width: 100%;
background: url('./../public/assets/About Us  BG.jpg') no-repeat center center;
background-size: cover;
background-attachment: scroll;

}

/* Add other backgrounds similarly... */
.services-background {
width: 100%;
background: url('./../public/assets/Sherbimet BG.jpg') no-repeat center center;
background-size: cover;
background-attachment: scroll;
}

.contact-background {
width: 100%;
background: url('./../public/assets/Contact BG.jpg') no-repeat center center;
background-size: cover;
background-attachment: scroll;
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
.container {
  width: var(--container-width-md);
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.2rem;
}

.btn {
  padding: 0.4rem 2rem; /* Increases button size */
}

.container, .main-container {
  padding: 0 20px; /* Adds padding on the sides */
}


.why-us {
  flex-direction: column;
  text-align: center;
  padding: 1rem; /* Reduce padding for smaller screens */
}

.why-us__content {
  width: 100%;
  padding-left: 0; /* Remove padding on smaller screens */
  text-align: center; /* Center text on smaller screens */
}
}

/* Responsive Phones */
@media screen and (max-width: 768px) {
.container {
  width: var(--container-width-sm); /* Uses smaller container width */
}
.header-home {
  background-attachment: scroll; /* Fix background attachment for smaller screens */
}
.btn {
  padding: 0.4rem 1.5rem; /* Reduces button size for space */
}

/* Increase base text size for readability */
body {
  font-size: 1rem; /* Increases text size */
}

/* Make images and sections adapt to smaller screens */
img, .section {
  width: 100%;
  height: auto;
}

.hero-container {
  width: 100%;
  background-size: cover;
background-attachment: scroll;
}


.hero-div h1 {
  font-size: 2.5rem; /* Adjusts hero text size */
}


.why-us {
  flex-direction: column;
  padding: 1rem; /* Reduce padding for smaller screens */
}

.why-us__content {
  width: 100%;
  padding-left: 0; /* Remove padding on smaller screens */
  text-align: center; /* Center text on smaller screens */
}

.types-title {
  text-align: center;
  font-size: 2rem;
  color: var(--color-secondary);
  padding-top: 20px;
  padding-bottom: 10px;
}
}

@media screen and (max-width: 305px) {
#spline-container::after {
  width: 100%; /* Adjust to 70% of the container's width */
}
}


/*====================  Services Overview Section  =============================*/

.services-overview-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center align both content and image */
  padding: 30px 30px; /* Add padding top and bottom */
  }
  
  .services-overview-title {
  align-self: center; /* Center the title */
  padding-top: 20px; /* Space from the top */
  text-align: center; /* Center align text */
  margin-bottom: 4rem; /* Add margin bottom to separate from blobs */
  }
  
  .services-overview-title h1 {
  font-size: 2.4rem; /* Increase font size */
  margin:30px;
  }
  
  .services-overview-blobs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; /* Increase gap between blobs */
  margin: 0; /* Remove margin-left */
  margin-bottom: 60px;
  }
  
  .service-overview-blob-art {
  background: var(--color-secondary-transparent);
  backdrop-filter: blur(2px);
  border-radius: 20px; /* Rounded corners for the blob */
  padding: 20px; /* Padding inside the blob */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none; /* Remove underline from the link */
  color: var(--color-background); /* Text color */
  font-weight: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); /* Shadow for the blob */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .service-overview-blob-art:hover {
  transform: translateY(-5px); /* Slightly raise the blob on hover */
  box-shadow: 0px 8px 3px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  }
  
  .service-overview-blob-art img {
  max-width: 70px; /* Increase based on your icon size */
  margin-bottom: 3px; /* Space between icon and text */
  padding: 10px;
  }
  
  .service-overview-blob-art span {
  margin-bottom: -20px;
  padding-top: -50px;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 1024px) {
  .services-overview-blobs {
      grid-template-columns: repeat(2, 1fr); /* Change to 2 columns on tablets */
  }
  }
  
  @media screen and (max-width: 768px) {
  .services-overview-blobs {
      grid-template-columns: 1fr; /* Stack blobs vertically on mobile */
    
    
  }
  .btn-about{
    text-align: center;
    align-self: center;
    margin: 0 auto;
  }
  }
  