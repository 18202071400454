.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    text-align: center;
    background-color: var(--color-background);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    opacity: 1;
    transition: opacity 1s ease-in-out; /* Transition for fading out */
  }
  
  .loading-screen.fade-out {
    opacity: 0; /* Fades out the loading screen */
  }
  
  .loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loading-logo {
    width: 100px; /* Adjust this size as needed */
    margin-bottom: 30px; /* Space between the logo and the loader */
  }
  
  