*, *::before, *::after {
    box-sizing: border-box;
}



.price.previous-price {
    text-decoration: line-through;
    font-size: 24px;
    margin-top: -20px;
    color: var(--color-primary);
    font-style: italic;
}

.price-comparison {
    display: flex;
    justify-content:center;
    align-items: stretch;
    width: 100%;
    margin: 0rem;
    flex-wrap: wrap; /* Allows columns to wrap if necessary */

}

.price-column {
    background-color: var(--color-background-transparent-offer);
    backdrop-filter: blur(2px);
    box-shadow: 0 7px 30px rgba(52, 31, 97, 0.1);
    padding: 2rem;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 275px;
    max-width: 275px;
    border-radius: 8px;
    color: var(--color-secondary);
    text-align: center;
    margin: 3px;
    transition: var(--transition);
}

.price-column:hover {
    transform: scale(1.05);
}

.price-column.popular {
    background-color: var(--color-secondary-transparent-offer);
    box-shadow: 0 7px 30px rgba(52, 31, 97, 0.1);
    color: var(--color-background);
    
}

.price-column.normal {
    background-color: var(--color-secondary-transparent-offer);
    backdrop-filter: blur(2px);
    box-shadow: 0 7px 30px rgba(52, 31, 97, 0.1);
    color: var(--color-background);
    
}

.price-colum:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.price-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
}

.price {
    font-size: 3rem;
    display: flex;
    margin-bottom: 10px;
}

.dollar-sing {
    font-size: 1.5rem;
    margin-top: .5;
    margin-right: .25rem;
}

.per-month {
    font-size: .75rem;
    align-self: flex-end;
    margin-bottom: 1.1rem;
    text-transform: uppercase;
}

.plan-name {
    text-transform: uppercase;
    font-size: .9rem;
    margin-top: 1rem;
    margin-bottom: 0;
}

.divider {
    height: 1px;
    width: 100px;
    background-color: var(--color-primary);
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin: 40px auto;
}

.feature {
    
font-family: "Outfit-Regular", sans-serif;

}


@media (max-width: 768px) {
    .price-comparison {
        flex-direction: column; /* Stack the columns vertically */
    }

    .price-column {
        max-width: 100%; /* Full width on mobile */
        margin-bottom: 1.5rem; /* Add spacing between stacked columns */
    }

    .offers-title {
        margin: 0 auto;
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .offers-description {
        text-align: center;
        padding: 0 40px;
        margin-top: -5rem;
    }
    

    


}

/* Tablet view (price columns two by two) */
@media (min-width: 769px) and (max-width: 1200px) {
    .price-comparison {
        flex-direction: row;
        flex-wrap: wrap; /* Ensure columns wrap into two rows */
        padding: 40px;
    }

    .price-column {
        max-width: calc(50% - 6px); /* Two columns in a row, accounting for margins */
    }
}
