/*====================  Navbar  =============================*/

.page-wrapper {
    width: auto;
    height: auto;
  }
  
  .nav-wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    transition: background-color 0.3s ease;
    background-color: transparent; /* Set initial background to transparent */
    z-index: 998; /* Ensures the navbar stays on top */
  }
  
  .nav-wrapper.scrolled {
    background-color: var(--color-white);
    transition: background-color 200ms linear;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.192); /* Adjust shadow to match the design */
  }
  
  .navbar {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    height: 80px;
    overflow: hidden;
  }
  
  .navbar img {
    height: 50px;
    width: auto;
    justify-self: start;
    margin-left: 20px;
    transition: all 1200ms ease;
    filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.2));
  
  }
  
  .navbar img:hover {
    transform: scale(1.3);
  }
  
  .navbar ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    justify-self: end;
  }
  
  .nav-item a {
    color: var(--color-white);
    font-size: 0.9rem;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.3s ease-out;
    transition: all 1000ms ease;
    margin: 20px;
    font-family: "Outfit-Bold";
  }
  
  .nav-item a.scrolled {
    color: var(--color-background);
  }
  
  .nav-item a:hover {
    color: var(--color-secondary);
  }
  
  .nav-item a:hover.scrolled {
    color: var(--color-primary);
  }
  
  /* Dropdown Menu */
  .nav-item.has-dropdown .dropdown-menu {
    display: none;
    position: absolute;
    background-color: transparent;
    z-index: 999;
    width: 90px;
    border: 22px;
    transition: background-color 0.3s ease;
    margin-top: 26.5px;
  }
  
  .nav-item.has-dropdown .dropdown-menu.scrolled {
    background-color: var(--color-white);
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.4);
    transition: background-color 200ms linear;
  }
  
  .nav-item.has-dropdown .dropdown-menu.show {
    display: block;
    border-radius: 0 0 22px 22px;
  }
  
  .nav-item.has-dropdown .dropdown-menu li,
  .nav-item.has-dropdown .dropdown-menu .last-link {
    padding: 12px 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    transition: var(--transition);
    padding-left: 0px;
  }
  
  .nav-item.has-dropdown .dropdown-menu li.scrolled,
  .nav-item.has-dropdown .dropdown-menu .last-link.scrolled {
    color: var(--color-background);
  }
  
  .country-flags {
    width: 2em;
    height: 1.5em;
    margin-right: 7px;
    text-align: center;
    border-radius: 50%;
  }
  
  .nav-item.has-dropdown .dropdown-menu li:hover {
    background-color: var(--color-primary);
  }
  
  
  .nav-item.has-dropdown .dropdown-menu .last-link:hover {
    border-radius: 0 0 22px 22px;
  }
  
  
  /* MOBILE MENU & ANIMATION */
  
  .menu-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: var(--color-white);
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .menu-toggle .bar.scrolled {
    background-color: var(--color-background);
  }
  
  .menu-toggle {
    justify-self: end;
    margin-right: 25px;
    display: none;
  }
  
  .menu-toggle:hover {
    cursor: pointer;
  }
  
  #mobile-menu.is-active .bar:nth-child(2) {
    opacity: 0;
  }
  
  #mobile-menu.is-active .bar:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
    -ms-transform: translateY(8px) rotate(45deg);
    -o-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }
  
  #mobile-menu.is-active .bar:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    -ms-transform: translateY(-8px) rotate(-45deg);
    -o-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }
  
  /* Mobile Devices - Phones/Tablets */
  
  @media only screen and (max-width: 720px) { 
  
    /* MOBILE HEADINGS */
    
    h1 {
      font-size: 1.9rem;
    }
    
    h2 {
      font-size: 1rem;
    }
    
    p {
      font-size: 0.8rem;
    }
    
    /* MOBILE NAVIGATION */
       
    .navbar ul {
      display: flex;
      flex-direction: column;
      position: fixed;
      justify-content: start;
      top: 55px;
      background-color: var(--color-background);
      width: 100%;
      height: calc(100vh - 55px);
      transform: translate(-101%);
      text-align: center;
      overflow: hidden;
    }
  
    .navbar ul.scrolled {
      background-color: var(--color-white);
    }
    
    .navbar li {
      padding: 15px;
    }
    
    .navbar li:first-child {
      margin-top: 50px;
    }
    
    .navbar li a {
      font-size: 1rem;
    }
     
    .menu-toggle, .bar {
      display: block;
      cursor: pointer;
    }
    
    .mobile-nav {
      transform: translate(0%)!important;
    }
  
    .nav-item.has-dropdown .dropdown-menu {
      position: fixed;
      left: 50%;
      top: 100%;
      transform: translate(-50%, -50%);
      display: none;
      background-color: var(--color-background);
      z-index: 1000;
      border-radius: 0 0 22px 22px;
      width: 100%;
      padding: 10px;
      margin: auto;
    }
  
    .nav-item.has-dropdown .dropdown-menu.scrolled {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0); /* Adjust shadow to match the design */
    }
  
    .nav-item.has-dropdown .dropdown-menu.show {
      display: block;
    }
  
    .nav-item.has-dropdown .dropdown-menu li {
      padding: 5px 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
    }
  
    .nav-item.has-dropdown .dropdown-menu li.scrolled {
      color: var(--color-background);
    }
  
    .nav-item.has-dropdown .dropdown-menu li .country-flags {
      margin-right: 10px;
    }
  
    .nav-item.has-dropdown .dropdown-menu li:hover {
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  
  }