/*====================  Services  =============================*/
  
.services-section {
  display: flex;
  justify-content: center;
  align-items: center; /* Center align both content and image */
  padding: 30px 0px; /* Add padding top and bottom */

}


.service-overview-blob-art img {
  width: 80px;  /* Adjust this to your preferred size */
  height: 80px; /* Adjust this to your preferred size */
  margin: 0 auto; /* Ensure the image is centered horizontally */
  display: block; /* Ensure the image is a block-level element */
}

.title-container {
  align-self: flex-start; /* Align title to the left */
  margin-left: 0px; /* Space from the left edge */
  padding-top: 20px; /* Space from the top */
}

.title {
  font-size: 1.8rem;
  color: var(--color-background);
  text-align: center;
}



.services-blobs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin: 0; /* Remove margin-left */
}

.service-blob {
  background:var(--color-secondary-transparent);
  border-radius: 22px; /* Rounded corners for the blob */
  padding: 25px; /* Padding inside the blob */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none; /* Remove underline from the link */
  color: var(--color-background); /* Text color */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); /* Shadow for the blob */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */


}

.service-blob:hover {
  transform: translateY(-5px); /* Slightly raise the blob on hover */
  box-shadow: 0px 8px 3px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.service-blob img {
  max-width: 120px; /* Adjust based on your icon size */
  margin-bottom: 10px; /* Space between icon and text */
}

.services-image {
  flex: 1; /* Take up only the necessary space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align to top */
}

.service-phone {
  max-width: 100%; /* Make sure it doesn't exceed the parent container */
  margin-top: -5rem;
  filter: drop-shadow(7px 8px 1px rgba(26, 26, 26, 0.374)); /* Optional: Larger shadow on hover for a subtle effect */
  transition: var(--transition);
}

.service-phone:hover {
  filter: drop-shadow(10px 10px 1px rgba(26, 26, 26, 0.482)); /* Horizontal offset, Vertical offset, Blur radius, Color */

}

.service-btn {
  position: relative; /* Position relative to its normal position */
  z-index: 10; /* Ensure it's above other elements */
  display: block; /* To apply margin */
  margin-top: -10rem;
  margin-left: 30rem;
}

  .services-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.services-title {
    margin: 40px 0 20px;
    font-size: 3rem;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 40px;
    color: var(--color-secondary);
}

.service-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
    max-width: 1200px;
   margin-bottom: 100px;
   text-align: right;
}

.service-section.reverse {
    flex-direction: row-reverse;
    text-align: left;
}

.service-image img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
   
}

.service-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    margin: 0 20px;
}

.service-content h2 {
    margin-bottom: 60px;
    font-size: 2rem;
    color: var(--color-secondary);
}

.service-content p {
    margin-bottom: 50px;
    font-size: 1rem;
}


.service-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; /* Default alignment for non-reversed sections */
    max-width: 600px;
    margin: 0 20px;
    text-align: right;
}

.service-section.reverse .service-content {
    align-items: flex-start; /* Align items to the right for reversed sections */
    text-align: left;
}
.service-section.reverse img {
   margin-left: 50px;
}
.service-section img {
    margin-right: 50px;

 }
 

 @media only screen and (max-width: 768px) {

  .services-blobs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3fr, 1fr)); /* adjust minmax() for your preferred size */
    gap: 1rem; /* Increase the gap between blobs */
    justify-content: center; /* Center grid items in the grid area */
    align-items: start; /* Align grid items to the start of their cell */
    padding: 1rem; /* Add padding around the entire grid */
  }

  .service-blob {
    /* Adjust the margin and alignment of individual blobs if needed */
    margin: 0.5rem; /* optional, for additional space around each blob */
  }

  .services-title-container {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  

  /* Center the whole services section */
  .services-section {
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center; /* Vertically centers the content */
    margin: 0 auto; /* Adds margin to the left and right */
    width: 100%; /* Ensure the section spans the full width */
    max-width: 960px; /* Adjust max-width to your design preference */
  }

  .services-title-container {
    align-self: auto; /* Align title to the left */
    text-align: center;
    margin-bottom: 30px; /* Space between the title and blobs */
    margin-left: 0; /* Space from the left edge */
  }

  
  .services-title {
    font-size: 1.8rem;
    text-align: center;
  }

  /* Ensure the services blobs wrap in rows of two */
  .services-blobs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 15px; /* This is the space between blobs */
    margin-bottom: 50px;
    
  }




  .service-blob {
    width: 200px;
    margin-bottom: 15px; /* Space between rows of blobs */
  }

  /* Center the services image and add space above it */
  .services-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Space between blobs and the image */
  }

  /* Adjust the service button to center it below the services image */
  .service-btn {
    display: block;
    margin: 20px auto; /* Adds margin on the top and bottom, auto centers it horizontally */
  }


  .services-title {
    font-size: 2.5rem;
    margin: 20px 0;
  }

  .service-section {
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;
    max-width: 100%;
    padding: 0 10px;
  }

  .service-image img {
    max-width: 100%;
    margin: 0 0 20px 0;
  }

  .service-content {
    align-items: center;
    text-align: center;
    margin: 0;
  }

  .service-content h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .service-section {
    text-align: center;
  }

  .service-content p {
    font-size: 0.9rem;
    margin-bottom: 30px;
  }

  .service-section.reverse {
    flex-direction: column;
    text-align: center;
  }

  .service-section .service-content h2 {
    text-align: center;
  }

  .service-content.reverse {
    text-align: center;
    margin: 0;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
  }

  .service-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    place-items: center;
    margin-left: auto;
  }
  .service-section.reverse .service-content {
    align-items: center; /* Align items to the right for reversed sections */
    text-align: center;
}
.service-section.reverse img {
   margin-left: 0px;
}
.service-section img {
    margin-right: 0px;

 }
 .service-section .service-content{
  text-align: center;
 }
}
