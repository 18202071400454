.footer {
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  color: var(--color-white);
  justify-content: space-between;
  font-family: "Outfit-Bold";
}

.footer-img img {
  height: 100px;
  transition: var(--transition);
}

.footer-img img:hover {
  transform: scale(1.1)
}

.location-icon {
  transition: var(--transition);
  margin-right: 15px; /* Adjust as needed for spacing between columns */
  height: 40px;
}

.location-icon:hover {
  transform: scale(1.1)
}

.footer-copy {
  text-align: center; /* Centers the text horizontally */
  opacity: 0.78; /* Sets the opacity to 78% */
  width: 100%; /* Ensures it spans the full width of its container */
  order: 3; /* Puts it at the end of the footer, even if other elements have flex order */
  font-size: 15px;
  padding-bottom: 5px;
  padding-top: 50px;
}

.footer-copy-synaps {
  text-align: center; /* Centers the text horizontally */
  opacity: 0.78; /* Sets the opacity to 78% */
  width: 100%; /* Ensures it spans the full width of its container */
  order: 3; /* Puts it at the end of the footer, even if other elements have flex order */
  font-size: 15px;
  padding-bottom: 30px;
}



.footer-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 200px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-column:not(:last-child) {
  margin-right: 20px; /* Adjust as needed for spacing between columns */
}

.footer-logo, .footer-location, .footer-contact, .footer-socials {
  margin-bottom: 10px; /* Adjust as needed */
}

.footer-logo{
margin-left: -190px;
}
.footer-slogan, .footer-copy {
 
  margin-top: auto; /* Pushes the slogan and copyright to the bottom */
}

.footer-slogan {
  margin-top: 20px;
}


.footer-link {
  display: block;
  font-family: "Outfit-Bold";
  margin-bottom: 5px; /* Adjust as needed for spacing between links */
  text-decoration: underline;
  transition: var(--transition)
  
}


.footer-link:hover {
  color: var(--color-primary);
}

.blog-log-in-btn {
  transition: var(--transition);
}


.blog-log-in-btn:hover {
  color: var(--color-primary);
}


.socials-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.social-row {
  display: flex;
  align-items: center; /* This will vertically center align the icon with the text */
  margin-bottom: 35px; /* Spacing between rows */
}

.social-row img {
  margin-right: 8px; /* Space between the icon and text, adjust as necessary */
}


.social-row img {
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  margin-right: 15px; /* Space between the icon and text */
  /* If you want to keep the images' aspect ratio, you can set only one dimension (width or height) and set the other to 'auto'. */

}


.footer-copy-designed-by-synaps {
  font-size: 20px;
  font-family: "Outfit-Bold";
  font-weight: 600;
  text-decoration:underline;
}

@media screen and (max-width: 1024px) {

  .footer-logo{
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
.footer-top, .footer-bottom {
  flex-direction: column;
}

.footer-column {
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px; /* Adjust as needed for spacing between mobile columns */
}

}

.privacy-policy {
  transition: var(--transition);
  color: var(--color-white);
  opacity: 50%;
  font-size: 15px;
  margin-top: 40px;
}

.privacy-policy:hover {
  color: var(--color-secondary);
}

